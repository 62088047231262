import { graphql } from 'gatsby'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import PageBanner from '../components/PageBanner'
import PageSection from '../components/PageSection'
import { useIntl } from '../utils/IntlContext'
import { getLocalizedRootUrlPrefix } from '../utils/locales'

export const query = graphql`
  query Page($contentful_id: String!, $locale: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      set_as_homepage
      node_locale
      languages
      meta_title
      meta_description
      include_logos
      banner {
        ...PageBanner
      }
      sections {
        ...PageSection
      }
    }
    successStoriesPage: contentfulReactPage(
      contentful_id: { eq: "35SjrERFSPauSTpENszW1i" }
      node_locale: { eq: $locale }
    ) {
      slug
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Page = ({
  location,
  pageContext: { translatedPages },
  data: {
    contentfulPage: { contentful_id, banner, include_logos, sections },
    successStoriesPage,
  },
}) => {
  const { locale } = useIntl()

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <PageBanner
        showLogos={include_logos}
        banner={banner}
      />
      {sections && (
        <PageSection
          contentfulId={contentful_id}
          successStoriesPath={getLocalizedRootUrlPrefix(successStoriesPage, locale)}
          sections={sections}
          location={location}
        />
      )}
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: {
    canonicalUrl,
    intl: { locale },
    translatedPages,
  },
  data: {
    contentfulPage: { meta_title, meta_description, set_as_homepage, slug },
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  const vcvBlueLogoUrl = `${siteUrl}/assets/images/vcv_blue.png`

  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      ldJson={
        set_as_homepage && locale === 'en-US'
          ? {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: 'VisualCV',
              logo: vcvBlueLogoUrl,
              email: 'team@visualcv.com',
              url: canonicalUrl,
              sameAs: [
                'https://www.facebook.com/YourVisualCV',
                'https://x.com/visualcv',
                'https://ca.linkedin.com/company/visualcv',
              ],
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  contactType: 'customer service',
                  url: 'https://support.visualcv.com',
                  email: 'team@visualcv.com',
                },
              ],
            }
          : slug === 'google-docs-resume-templates'
            ? {
                '@context': 'http://schema.org',
                '@type': 'SoftwareApplication',
                name: meta_title,
                applicationCategory: 'https://schema.org/WebApplication',
                operatingSystem: 'Android, iOS, Windows, macOS',
                description: meta_description,
                publisher: {
                  '@type': 'Organization',
                  name: 'VisualCV',
                  logo: {
                    '@type': 'ImageObject',
                    url: vcvBlueLogoUrl,
                  },
                },
                offers: {
                  '@type': 'Offer',
                  priceCurrency: 'USD',
                  price: '0',
                },
              }
            : undefined
      }
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default Page
